import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { SiteContext } from '@homeplay/app';
import GameItem from './GameItem';
import classes from './GamesList.module.scss';

const getGamePath = (siteName, game) => {
  switch (game.slug) {
    case 'fafi':
      return `/${siteName ? siteName + '/' : ''}fafi`;
    case 'spaviator':
      return `/${siteName ? siteName + '/' : ''}aviator`;
    default:
      return `/${siteName ? siteName + '/' : ''}${game.productId}/game/${game.slug}`;
  }
};

const GamesList = ({ games }) => {
  const { siteName } = useContext(SiteContext);
  const { happyHour, happyHourBonus } = useSelector(
    (state) => state.promotions
  );

  const gamesList = games.map((game) => (
    <li key={game.slug} className={'game ' + game.productId}>
      <GameItem
        gamePath={getGamePath(siteName, game)}
        gameImg={game.img.path}
        alt={game.name}
        imgWigth={game.img.width}
        imgHeight={game.img.height}
        isNew={game.isNew}
        hasBonus={game.hasBonus}
        activeHappyHour={
          happyHour ||
          (happyHourBonus &&
            happyHourBonus.bonusStates &&
            happyHourBonus.bonusStates.length > 0)
        }
        productId={game.productId}
      />
    </li>
  ));

  return <ul className={classes.GamesList}>{gamesList}</ul>;
};

export default GamesList;
