import playnGoImg from '../../../shared/assets/images/playn-go.jpg';
import club888Img from '../../../shared/assets/images/club-888.jpg';
import greenDreamImg from '../../../shared/assets/images/green-dream.jpg';
import ptcImg from '../../../shared/assets/images/ptc.jpg';
import ppLiveCasinoImg from '../../../shared/assets/images/pp-live-casino.jpg';
import onlyPlayImg from '../../../shared/assets/images/only-play.jpg';
import fafiImg from '../../../shared/assets/images/fafi.jpg';
import rlnImg from '../../../shared/assets/images/rln.jpg';
import visoGamesImg from '../../../shared/assets/images/viso-games.jpg';
import netEntImg from '../../../shared/assets/images/netenttemp.jpg';
import olnImg from '../../../shared/assets/images/oln.jpg';
import aviatorImg from '../../../shared/assets/images/aviator.jpg';

export const PRODUCTS = {
  playnGo: {
    name: "Play'n GO",
    id: 'playn-go',
    img: {
      path: playnGoImg,
      width: '402',
      height: '230',
    },
  },
  club888: {
    name: 'CLUB 888',
    id: 'club-888',
    img: {
      path: club888Img,
      width: '402',
      height: '230',
    },
  },
  greenDream: {
    name: 'Green Dream',
    id: 'green-dream',
    img: {
      path: greenDreamImg,
      width: '402',
      height: '230',
    },
  },
  ptc: {
    name: 'Palm Treasures Club',
    id: 'ptc',
    img: {
      path: ptcImg,
      width: '402',
      height: '230',
    },
  },
  onlyPlay: {
    name: 'Only Play',
    id: 'only-play',
    img: {
      path: onlyPlayImg,
      width: '402',
      height: '230',
    },
  },
  visoGames: {
    name: 'VisoGames',
    id: 'viso-games',
    img: {
      path: visoGamesImg,
      width: '402',
      height: '230',
    },
  },
  netEnt: {
    name: 'NetEnt',
    id: 'net-ent',
    img: {
      path: netEntImg,
      width: '402',
      height: '230',
    },
  },
  ppLiveCasino: {
    name: 'Pragmatic Play Live Casino',
    id: 'pp-live-casino',
    img: {
      path: ppLiveCasinoImg,
      width: '402',
      height: '230',
    },
  },
  fafi: {
    name: 'Fafi',
    id: 'fafi',
    img: {
      path: fafiImg,
      width: '402',
      height: '230',
    },
  },
  rln: {
    name: 'Rapid Lucky Numbers',
    id: 'rln',
    img: {
      path: rlnImg,
      width: '402',
      height: '230',
    },
  },
  oln: {
    name: 'Online Lucky Numbers',
    id: 'oln',
    img: {
      path: olnImg,
      width: '402',
      height: '230',
    },
  },
  aviator: {
    name: 'Aviator',
    id: 'aviator',
    img: {
      path: aviatorImg,
      width: '402',
      height: '230',
    },
  },
};

export const parseUnitParams = (params) => {
  const unit = params.unit;
  if (!unit) {
    throw new Error('error');
  }
  if (unit.site && !unit.site.active) {
    throw new Error('site_not_active');
  }
  if (unit.config && unit.config.funds_mode !== 'gs_ticket') {
    throw new Error('ticket_not_support');
  }

  let gamesConfigs = {};
  if (params.games) {
    gamesConfigs = getGamesConfigs(params.games);
  }

  const unitConfigs = {
    id: unit.id,
    balance: unit.fin_account.balance,
    bonus: unit.fin_account.bonus_balance,
    depositBalance: unit.fin_account.deposit_balance || 0,
    ...gamesConfigs,
  };

  if (unit.config) {
    unitConfigs.showAcoinLink = unit.config.show_acoin_link;
    unitConfigs.cashoutAtmEnabled = unit.config.im_withdrawal_enabled;
    unitConfigs.cashoutEftEnabled = unit.config.eft_withdrawal_enabled;
    unitConfigs.cashoutTicketEnabled = unit.config.ticket_cashout_enabled;
    unitConfigs.cashoutAtmMinAmount = unit.config.im_withdrawal_min_amount;
    unitConfigs.cashoutEftMinAmount = unit.config.eft_withdrawal_min_amount;
    unitConfigs.cashoutAtmFee = unit.config.im_withdrawal_fee;
    unitConfigs.cashoutEftFee = unit.config.eft_withdrawal_fee;
    unitConfigs.cashoutFreeRequestsNum = unit.config.free_bank_withdrawal_count;
    unitConfigs.cashoutRequestFeePeriod =
      unit.config.bank_withdrawal_fee_period;
    unitConfigs.supportParams = {
      useChat: !!unit.config.chat_enabled,
      accountId: unit.config.chat_account_id,
      whatsapp: unit.config.whatsapp_phone,
    };
  }

  return unitConfigs;
};

export const handleError = (error, redirect) => {
  let errorMessage;
  let errorRedirect = redirect;
  let additionalInfo = null;

  if (error.response && error.response.data && error.response.data.error) {
    switch (error.response.data.error.code) {
      case 'units_ctx:sites:not_found':
        errorMessage =
          'The site name you have entered is not found. Please check and re-enter.';
        errorRedirect = '/';
        break;
      case 'api_client:acoin:invalid_merchant_token':
        errorMessage = `This voucher cannot be used on this site. Contact ${process.env.REACT_APP_NAME} support for assistance.`;
        break;
      case 'api_client:acoin:invalid_voucher':
        errorMessage = 'Invalid Voucher';
        break;
      case 'api_client:acoin:voucher_redeemed':
        errorMessage = 'Voucher has already been used.';
        break;
      case 'api_client:acoin:server':
        errorMessage = `Something wrong. Contact ${process.env.REACT_APP_NAME} support for assistance.`;
        if (
          error.response.data.error.msg ===
          'aCoin error [400]: Invalid phone number [400]'
        ) {
          additionalInfo = 'Invalid phone number';
        } else {
          additionalInfo = error.response.data.error.msg;
        }
        break;
      case 'fin_ctx:fin_ops:no_funds':
        errorMessage = 'No funds available to cash out.';
        break;
      case 'units_ctx:sites:not_active':
        errorMessage = 'This site is not active now.';
        errorRedirect = '/';
        break;
      case 'units_ctx:games:not_found':
        errorMessage = 'Game not found.';
        break;
      case 'api_client:game_server:processing':
        if (
          error.response.data.error.msg === '7R Game Server error: Not Active'
        ) {
          errorMessage = 'Ticket is not active';
        } else if (
          error.response.data.error.msg ===
          '7R Game Server error: Owner Id mismatch'
        ) {
          errorMessage = 'Ticket Access Denied';
          additionalInfo =
            'This ticket has been cashed out by another user. Please note that a ticket can only be loaded by the user who originally cashed it out from their account.';
        } else {
          errorMessage = error.response.data.error.msg.replace(
            '7R Game Server error: ',
            ''
          );
        }
        break;

      default:
        errorMessage = error.response.data.error.msg;
    }
  } else if (error.data && error.data.errorCode) {
    switch (error.data.errorCode) {
      case 101:
        errorMessage =
          'The site name you have entered is not found. Please check and re-enter.';
        errorRedirect = '/';
        break;
      default:
        errorMessage = error.data.message;
    }
  } else if (error.message) {
    switch (error.message) {
      case 'site_not_active':
        errorMessage = 'This site is not active now';
        errorRedirect = '/';
        break;
      case 'ticket_not_support':
        errorMessage = 'This site does not support load funds by Ticket';
        errorRedirect = '/';
        break;
      case 'auth:wrong_password':
        errorMessage = 'Wrong password.';
        break;
      default:
        errorMessage = 'An unknown error occurred.';
        additionalInfo = error.message;
    }
  } else {
    errorMessage = 'An unknown error occurred!';
  }

  return { message: errorMessage, redirect: errorRedirect, additionalInfo };
};

const getGamesConfigs = (games) => {
  const gamesConfigs = { products: [] };

  if (games.popular) {
    gamesConfigs.topGames = games.popular.map((game) =>
      parseGameParams(game, { type: '-top.png', width: '248', height: '165' })
    );
  }

  if (games.ptc && games.ptc.length) {
    gamesConfigs.products.push(PRODUCTS.ptc);
    gamesConfigs.ptcGames = games.ptc.map((game) =>
      parseGameParams(
        game,
        { type: '.png', width: '160', height: '114' },
        PRODUCTS.ptc.id
      )
    );
  }

  if (games.club888 && games.club888.length) {
    gamesConfigs.products.push(PRODUCTS.club888);
    gamesConfigs.club888Games = games.club888.map((game) =>
      parseGameParams(
        game,
        { type: '.jpg', width: '301', height: '180' },
        PRODUCTS.club888.id
      )
    );
  }

  if (games.greendream && games.greendream.length) {
    gamesConfigs.products.push(PRODUCTS.greenDream);
    gamesConfigs.greenDreamGames = games.greendream.map((game) =>
      parseGameParams(
        game,
        { type: '.jpg', width: '301', height: '180' },
        PRODUCTS.greenDream.id
      )
    );
  }

  if (games.png && games.png.length) {
    gamesConfigs.products.push(PRODUCTS.playnGo);
    gamesConfigs.playnGoGames = games.png.map((game) =>
      parseGameParams(
        game,
        { type: '.jpg', width: '301', height: '180' },
        PRODUCTS.playnGo.id
      )
    );
  }

  if (games.op && games.op.length) {
    gamesConfigs.products.push(PRODUCTS.onlyPlay);
    gamesConfigs.onlyPlayGames = games.op.map((game) =>
      parseGameParams(
        game,
        { type: '.jpg', width: '301', height: '180' },
        PRODUCTS.onlyPlay.id
      )
    );
  }

  if (games['pp-lg'] && games['pp-lg'].length) {
    gamesConfigs.products.push(PRODUCTS.ppLiveCasino);
    gamesConfigs.ppLiveCasinoGames = games['pp-lg'].map((game) => ({
      id: game.vendor_data.gameID,
      slug: game.slug,
      productId: PRODUCTS.ppLiveCasino.id,
      name: game.name,
    }));
  }

  if (games.fafi && games.fafi.length) {
    gamesConfigs.products.push(PRODUCTS.fafi);
  }

  if (games.viso && games.viso.length) {
    gamesConfigs.products.push(PRODUCTS.visoGames);
    gamesConfigs.visoGames = games.viso.map((game) =>
      parseGameParams(
        game,
        { type: '.png', width: '300', height: '200' },
        PRODUCTS.visoGames.id
      )
    );
  }

  if (games.netent && games.netent.length) {
    gamesConfigs.products.push(PRODUCTS.netEnt);
    gamesConfigs.netEntGames = games.netent.map((game) =>
      parseGameParams(
        game,
        { type: '.jpg', width: '301', height: '180' },
        PRODUCTS.netEnt.id
      )
    );
  }

  if (games.rln && games.rln.length) {
    gamesConfigs.products.push(PRODUCTS.rln);
  }

  if (games.oln && games.oln.length) {
    gamesConfigs.products.push(PRODUCTS.oln);
    gamesConfigs.olnGames = games.oln.map((game) =>
      parseGameParams(
        game,
        { type: '.png', width: '264', height: '158' },
        PRODUCTS.oln.id
      )
    );
  }

  if (games['popular-lotto']) {
    gamesConfigs.topOlnGames = games['popular-lotto'].map((game) =>
      parseGameParams(
        game,
        { type: '.png', width: '264', height: '158' },
        PRODUCTS.oln.id
      )
    );
  }

  if (games.aviator && games.aviator.length) {
    gamesConfigs.products.push(PRODUCTS.aviator);
  }

  if (games.bonus) {
    gamesConfigs.bonusGames = games.bonus.map((game) => parseGameParams(game));
  }

  return gamesConfigs;
};

const getGameImgParams = (productId) => {
  switch (productId) {
    case PRODUCTS.ptc.id:
      return { type: '.png', width: '160', height: '114' };

    case PRODUCTS.visoGames.id:
      return { type: '.png', width: '300', height: '200' };

    default:
      return { type: '.jpg', width: '301', height: '180' };
  }
};

const parseGameParams = (game, imgParams, productId) => {
  let gameProductId;
  let gameImgParams = imgParams;

  if (productId) {
    gameProductId = productId;
  } else {
    gameProductId = parseGameProductId(game.vendor);
    if (!gameImgParams) {
      gameImgParams = getGameImgParams(gameProductId);
    }
  }

  return {
    slug: game.slug,
    productId: gameProductId,
    name: game.name,
    img: {
      path: `/static/media/${game.slug.replaceAll('_', '-')}${gameImgParams.type}`,
      width: gameImgParams.width,
      height: gameImgParams.height,
    },
    isNew: !!game.tags.find((tag) => tag.slug === 'new'),
    hasBonus: !!game.tags.find((tag) => tag.slug === 'bonus'),
    isTop: !!game.tags.find((tag) => tag.slug === 'popular-lotto'),
  };
};

const parseGameProductId = (gameVendor) => {
  switch (gameVendor) {
    case '7r:png':
      return PRODUCTS.playnGo.id;

    case '7r:amatic':
      return PRODUCTS.ptc.id;

    case 'gmw':
      return PRODUCTS.club888.id;

    case '7r:novomatic':
      return PRODUCTS.greenDream.id;

    case 'vx':
      return PRODUCTS.visoGames.id;

    case '7r:nt':
      return PRODUCTS.netEnt.id;

    case 'pp:lg':
      return PRODUCTS.ppLiveCasino.id;

    case '7r:fafi':
      return PRODUCTS.fafi.id;

    case '7r:rln':
      return PRODUCTS.rln.id;

    case 'op':
      return PRODUCTS.onlyPlay.id;

    case '7r:oln':
      return PRODUCTS.oln.id;
  }
};
