import React, { useContext } from 'react';
import classes from './Header.module.scss';
import { Link } from 'react-router-dom';
import { selectUser, SiteContext } from '@homeplay/app';
import Balance from './Balance';
import { useSelector } from 'react-redux';

const Header = ({ headerContent, backPath, onClickBack }) => {
  const { siteName, isTouchUnit } = useContext(SiteContext);
  const user = useSelector(selectUser);
  const { balance, depositBalance, bonus } = useSelector((state) => ({
    balance: state.unit.balance,
    depositBalance: state.unit.depositBalance,
    bonus: state.unit.bonus,
  }));

  return (
    <header
      className={
        `${classes.Header} ${classes.HeaderWrapper} header-wrapper` +
        (isTouchUnit ? ' ' + classes.TouchScreen : '')
      }
    >
      <div className={classes.Wrap}>
        <Link to={`/${siteName}`} className={classes.Link}>
          <svg
            width="29"
            height="26"
            viewBox="0 0 29 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.652 0H15.0152C19.5511 4.35979 24.1179 8.69768 28.6199 13.0848C28.7156 13.1204 28.9043 13.1916 29 13.2299V13.3394C27.3022 13.4899 25.5904 13.3695 23.887 13.416C23.8757 17.6116 23.8842 21.8044 23.8814 26H17.4084C17.4056 22.9867 17.4113 19.9762 17.4028 16.9629C15.4685 16.9356 13.5315 16.9493 11.5972 16.9575C11.5859 19.9707 11.5972 22.9867 11.5916 26H5.11864C5.11301 21.8044 5.12709 17.6088 5.11301 13.4133C3.40961 13.3804 1.70058 13.4625 0 13.3695C5.0567 9.01242 9.92194 4.43916 14.9927 0.0957895L14.652 0Z"
              fill="#C5C6C6"
            />
          </svg>
        </Link>
      </div>
      <div className="text-center">
        {headerContent ? (
          headerContent
        ) : (
          <Balance
            user={user}
            balance={balance}
            depositBalance={depositBalance}
            bonus={bonus}
          />
        )}
      </div>
      <div className={classes.Wrap}>
        {onClickBack ? (
          <span onClick={onClickBack} className={classes.Link}>
            <svg
              width="15"
              height="24"
              viewBox="0 0 15 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 3L11.943 0L0 12L11.943 24L15 21L6 12L15 3Z"
                fill="#C5C6C6"
              />
            </svg>
          </span>
        ) : (
          <Link
            to={backPath ? backPath : `/${siteName ?? ''}`}
            className={classes.Link}
          >
            {backPath ? (
              <svg
                width="15"
                height="24"
                viewBox="0 0 15 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 3L11.943 0L0 12L11.943 24L15 21L6 12L15 3Z"
                  fill="#C5C6C6"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 20.188L15.685 11.979L23.885 3.697L20.188 0L11.976 8.318L3.666 0.115L0 3.781L8.321 12.021L0.115 20.334L3.781 24L12.018 15.682L20.303 23.885L24 20.188Z"
                  fill="#C5C6C6"
                />
              </svg>
            )}
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
