import React, { useContext } from 'react';
import classes from './Header.module.scss';
import { SiteContext } from '@homeplay/app';
import { formatBalance, setMaskForPhone } from '@homeplay/utils';
import ButtonSecondary from '../UI/ButtonSecondary';
import Divider from '../UI/Divider';
import { Link } from 'react-router-dom';

const UserMenu = ({
  user,
  balance,
  bonus,
  depositBalance,
  onLogOutClick,
  isTouchUnit,
  cashoutAtmEnabled,
}) => {
  const { siteName } = useContext(SiteContext);

  return (
    <div className={classes.UserMenu}>
      <div>
        <Divider />
        <div className="inline-block">
          <span className="label">Phone</span>
          <span className="value">{setMaskForPhone(user.phone)}</span>
        </div>

        <div className="inline-block">
          <span className="label">First Name</span>
          <span className="value">{user.firstName}</span>
        </div>

        <div className="inline-block">
          <span className="label">Last Name</span>
          <span className="value">{user.lastName}</span>
        </div>

        <div className="inline-block">
          <span className="label">Total Balance</span>
          <span className="value">
            {formatBalance(balance + depositBalance)}
          </span>
        </div>

        <div className="inline-block">
          <span className="label">Free Spins</span>
          <span className="value">{formatBalance(bonus)}</span>
        </div>

        {cashoutAtmEnabled && !isTouchUnit && (
          <React.Fragment>
            <div className="inline-block">
              <span className="label">Available to Withdraw *</span>
              <span className="value">{formatBalance(balance)}</span>
            </div>
            <p className={classes.Info}>
              * Credits loaded to your account must be used to play. You can
              only withdraw what you have won.
            </p>
          </React.Fragment>
        )}

        <ButtonSecondary
          navPath={`/${siteName ? siteName + '/' : ''}cashout`}
          value="Cashout"
          customClass={classes.HeaderButton + ' d-block'}
        />

        <Link
          to={`/${siteName ? siteName + '/' : ''}statement`}
          className="link"
        >
          <svg
            width="24"
            height="24"
            className="stroke-primary"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>My Statement</span>
        </Link>
        <br />
        <Link
          to={`/${siteName ? siteName + '/' : ''}edit-profile`}
          className="link"
        >
          <svg
            width="20"
            height="18"
            className="stroke-primary"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.1908 6.348L16.3762 7.42125L5.89508 16.923L0.000976562 18L1.19138 12.6547L11.6684 3.15375L12.8522 4.227L2.71433 13.395L2.11375 16.083L5.07859 15.5408L15.1908 6.348ZM15.1445 0L12.8158 2.11275L17.5236 6.38175L19.854 4.266L15.1445 0V0ZM4.9545 14.0385L14.3131 5.5515L13.7299 5.02125L4.3713 13.5068L4.9545 14.0385Z"
              fill="#9B4090"
            />
          </svg>
          <span>Edit Profile</span>
        </Link>
        <br />
        <Link
          to={`/${siteName ? siteName + '/' : ''}reset-password`}
          className="link"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="fill-primary"
              d="M16 2C19.309 2 22 4.691 22 8C22 11.309 19.309 14 16 14C12.691 14 10 11.309 10 8C10 4.691 12.691 2 16 2ZM16 0C11.582 0 8 3.582 8 8C8 12.418 11.582 16 16 16C20.418 16 24 12.418 24 8C24 3.582 20.418 0 16 0ZM10.595 16.4L9.123 18H6V20H4V22H2V19.821L7.903 13.845C7.499 13.286 7.149 12.687 6.865 12.05L0 19V24H6V22H8V20H10L12.451 17.337C11.796 17.088 11.175 16.775 10.595 16.4ZM18 5C18.551 5 19 5.449 19 6C19 6.551 18.551 7 18 7C17.449 7 17 6.551 17 6C17 5.449 17.449 5 18 5ZM18 4C16.896 4 16 4.896 16 6C16 7.104 16.896 8 18 8C19.104 8 20 7.104 20 6C20 4.896 19.104 4 18 4Z"
            />
          </svg>
          <span>Reset Password</span>
        </Link>
        <br />
        <span onClick={onLogOutClick} className="link">
          <svg
            width="24"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="fill-primary"
              d="M16 7V3L24 10L16 17V13H8V7H16ZM14 17V16.917C12.822 17.602 11.458 18 10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C11.458 2 12.822 2.398 14 3.083V0.838C12.774 0.302 11.423 0 10 0C4.478 0 0 4.477 0 10C0 15.523 4.478 20 10 20C11.423 20 12.774 19.698 14 19.162V17Z"
            />
          </svg>
          <span>Log Out</span>
        </span>
      </div>
    </div>
  );
};

export default UserMenu;
