import { createSlice } from '@reduxjs/toolkit';
import { getCronSchedule } from '@homeplay/utils';

const initialState = {
  happyHour: null,
  happyHourBonus: {
    isComplete: false,
    isCongratsBanner: false,
    fetched: false,
    bonusStates: null,
  },
  happyHourConfigs: null,
};

export const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {
    setHappyHour: (state, action) => {
      if (!action.payload.happyHour) {
        state.happyHour = null;
      } else if (
        !state.happyHour ||
        state.happyHour.id === action.payload.happyHour.id
      ) {
        state.happyHour = action.payload.happyHour;
      }
    },
    setHappyHourBonus: (state, action) => {
      state.happyHourBonus = {
        isComplete: action.payload.isComplete,
        isCongratsBanner: action.payload.isCongratsBanner
          ? action.payload.isCongratsBanner
          : null,
        bonusStates: action.payload.bonusStates,
        fetched: true,
      };
    },
    updateHappyHourBonusState: (state, action) => {
      if (action.payload.bonusState.complete) {
        state.happyHourBonus.isComplete = true;
        state.happyHourBonus.isCongratsBanner = true;

        if (
          state.happyHour &&
          state.happyHour.id === action.payload.bonusState.promotionId
        ) {
          state.happyHour = null;
        }
      }
      const bonusStates = state.happyHourBonus.bonusStates
        ? [...state.happyHourBonus.bonusStates]
        : [];
      const stateIndex = bonusStates.findIndex(
        (state) => state.id === action.payload.bonusState.id
      );

      if (stateIndex >= 0) {
        bonusStates[stateIndex] = action.payload.bonusState;
      } else {
        bonusStates.push(action.payload.bonusState);
      }
      state.happyHourBonus.bonusStates = bonusStates;
      state.happyHourBonus.fetched = true;
    },
    removeHappyHourBonusState: (state, action) => {
      if (state.happyHourBonus.bonusStates) {
        let isComplete = false;
        state.happyHourBonus.bonusStates =
          state.happyHourBonus.bonusStates.filter((state) => {
            if (state.id !== action.payload.bonusStateId) {
              if (state.complete) {
                isComplete = true;
              }
              return true;
            }
            return false;
          });
        state.happyHourBonus.isComplete = isComplete;
        if (!isComplete) {
          state.happyHourBonus.isCongratsBanner = false;
        }
      }
    },
    setIsCongratsBanner: (state, action) => {
      state.happyHourBonus.isCongratsBanner = action.payload.value;
    },
    resetHappyHour: (state, action) => {
      state.happyHour = null;
      state.happyHourBonus = {
        isComplete: false,
        isCongratsBanner: false,
        fetched: false,
        bonusStates: null,
      };
    },
    setHappyHourConfigs: (state, action) => {
      state.happyHourConfigs = action.payload;
    },
  },
});

export const {
  setHappyHour,
  setHappyHourBonus,
  updateHappyHourBonusState,
  removeHappyHourBonusState,
  setIsCongratsBanner,
  resetHappyHour,
  setHappyHourConfigs,
} = promotionsSlice.actions;

export const selectIsCongratsBanner = (state) =>
  state.promotions.happyHourBonus.isCongratsBanner;
export const selectNearestHHTexts = (state) => {
  if (
    !state.promotions.happyHourConfigs ||
    !state.promotions.happyHourConfigs.length
  ) {
    return null;
  }
  const promotions = state.promotions.happyHourConfigs.map((happyHour) => {
    const startDate = new Date(Date.now() - happyHour.duration - 100);
    const schedule = getCronSchedule(happyHour.schedule, startDate);
    return { ...happyHour, nextDate: schedule.next() };
  });

  const nearestHH = promotions.reduce((nearestPromotion, promotion) => {
    if (promotion.nextDate < nearestPromotion.nextDate) {
      return promotion;
    }
    return nearestPromotion;
  }, promotions[0]);
  return nearestHH.texts;
};
export const selectHHConfigs = (state) => state.promotions.happyHourConfigs;

export default promotionsSlice.reducer;
