import React from 'react';
import classes from './Input.module.scss';
import Input from "./index";
import moment from 'moment';

const OTPInput = ({refLink, id, onChange, value, timerValue, onClickResend}) => {
    const handleChange = (value) => {
        onChange(value);
    };

    const formatTimerValue = (seconds) => {
        const format = seconds < 60 ? 's' : 'mm:ss'

        return moment().startOf('day')
            .seconds(seconds)
            .format(format);
    };

    return (
        <div className={classes.OTPInput}>
            <Input
                refLink={refLink}
                type="number"
                id={id}
                placeholder="Enter Code from SMS"
                onChange={handleChange}
                value={value}
                pattern='^[0-9]{0,4}$'
                error={value.length < 4}
                inputMode='numeric'
            />
            {timerValue > 0 ?
                <div className={classes.Resend}>
                    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.6235 10.5608L16.9253 7.37358L16.5288 7.05217L13.3216 9.50554C12.7322 9.39629 12.0973 9.55937 11.6677 9.99479C11.0251 10.6463 11.0871 11.6478 11.8066 12.2305C12.5261 12.8131 13.6298 12.7569 14.2725 12.1046C14.7021 11.6692 14.8095 11.0794 14.6235 10.5608ZM11.2093 2.13275V0H14.7021V2.13275C13.4456 1.94038 12.4388 1.94433 11.2093 2.13275ZM18.938 3.76675L20.1666 2.65288L21.4013 3.77229L20.2478 4.81808C19.8444 4.43492 19.406 4.08421 18.938 3.76675ZM2.4773 7.125H9.4629V8.70833H2.4773V7.125ZM0.730896 10.2917H7.7165V11.875H0.730896V10.2917ZM3.3505 13.4583H9.4629V15.0417H3.3505V13.4583ZM21.6877 11.0833C21.6877 15.4557 17.7784 19 12.9557 19C10.5195 19 8.32774 18.0856 6.74637 16.625H9.61047C10.6042 17.1245 11.7402 17.4167 12.9557 17.4167C16.8074 17.4167 19.9413 14.5754 19.9413 11.0833C19.9413 7.59129 16.8074 4.75 12.9557 4.75C11.7402 4.75 10.6042 5.04213 9.61047 5.54167H6.74637C8.32774 4.08104 10.5195 3.16667 12.9557 3.16667C17.7784 3.16667 21.6877 6.71096 21.6877 11.0833Z" fill="white"/>
                    </svg>
                    <span className={classes.Count}>{formatTimerValue(timerValue)} sec</span>
                </div>
                :
                <div onClick={onClickResend} className={classes.Resend}>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.9627 6.65267C13.6367 9.65867 11.092 12 8 12C6.20133 12 4.592 11.204 3.49133 9.95L4.32133 8.86133C5.17667 9.958 6.50533 10.6667 8 10.6667C10.3507 10.6667 12.296 8.91733 12.6153 6.65267H10.6667L13.3333 3.15267L16 6.65267H13.9627ZM3.38667 5.33333C3.71133 3.07533 5.65333 1.33333 8 1.33333C9.49133 1.33333 10.8187 2.03933 11.6733 3.132L12.5027 2.044C11.4027 0.793333 9.79533 0 8 0C4.912 0 2.37133 2.334 2.03933 5.33333H0L2.66667 8.83333L5.33333 5.33333H3.38667Z" fill="white"/>
                    </svg>
                    <span>Resend</span>
                </div>
            }
        </div>
    );
};

export default React.memo(OTPInput);
