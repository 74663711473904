import { createSlice } from '@reduxjs/toolkit';
import { setAnonymousUnit, setUnitToken } from './unitSlice';
import {
  assignUnitToUser,
  createUser,
  getLocalUserParams,
  getUser,
  getValidUserTokens,
  handleError,
  loginUser,
  setLocalUserParams,
  updateUserData,
  usersUnit,
} from '@homeplay/api';

const initialState = {
  token: null,
  user: null,
  error: null,
  loading: true,
  localError: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authStart: (state) => {
      state.error = null;
      state.loading = true;
    },
    authSuccess: (state, action) => ({
      token: action.payload.token,
      user: action.payload.user,
      error: null,
      loading: false,
      localError: false,
    }),
    authFail: (state, action) => {
      state.error = action.payload.error;
      state.loading = false;
      if (action.payload.localError) {
        state.localError = action.payload.localError;
      }
    },
    authLogout: () => ({
      token: null,
      user: null,
      error: null,
      loading: false,
      localError: false,
    }),
    resetAuthError: (state) => {
      state.error = null;
    },
    resetAuthLocalError: (state) => {
      state.localError = false;
    },
    disableAuthLoading: (state) => {
      state.loading = false;
    },
    updateUserSuccess: (state, action) => ({
      token: state.token,
      user: action.payload.user,
      error: null,
      loading: false,
      localError: false,
    }),
  },
});

export const {
  authStart,
  authSuccess,
  authFail,
  authLogout,
  resetAuthError,
  resetAuthLocalError,
  disableAuthLoading,
  updateUserSuccess,
} = authSlice.actions;

export const autoLogin = (siteName, isLandBased) => async (dispatch) => {
  const tokens = getLocalUserParams(siteName);
  if (tokens) {
    try {
      const userTokens = await getValidUserTokens(tokens, siteName);
      if (userTokens) {
        const user = await getUser(userTokens.access.token);
        const usersUnitToken = await usersUnit(
          userTokens.access.token,
          siteName,
          isLandBased
        );
        dispatch(setUnitToken({ token: usersUnitToken }));
        dispatch(
          authSuccess({
            token: userTokens,
            user,
          })
        );
      } else {
        dispatch(logout(siteName, isLandBased));
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        (error.response.data.error.code === 'auth:token_expired' ||
          error.response.data.error.code === 'auth:unauthenticated')
      ) {
        dispatch(logout(siteName, isLandBased));
      } else {
        dispatch(authFail({ error: handleError(error) }));
      }
    }
  } else {
    dispatch(logout(siteName, isLandBased));
  }
};

export const login =
  (phoneNumber, password, siteName, isLandBased) => async (dispatch) => {
    dispatch(authStart());
    try {
      const { user, userTokens } = await loginUser(
        phoneNumber,
        password,
        siteName
      );
      const usersUnitToken = await usersUnit(
        userTokens.access.token,
        siteName,
        isLandBased
      );
      dispatch(
        authSuccess({
          token: userTokens,
          user,
        })
      );
      dispatch(setUnitToken({ token: usersUnitToken }));
      setLocalUserParams(userTokens, siteName);
    } catch (error) {
      let err = error;
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.code === 'auth:unauthenticated'
      ) {
        err = new Error('auth:wrong_password');
      }
      dispatch(
        authFail({
          error: handleError(err),
          localError: true,
        })
      );
    }
  };

export const logout = (siteName, isLandBased) => async (dispatch) => {
  dispatch(setAnonymousUnit(siteName, isLandBased));
  dispatch(authLogout());
  localStorage.removeItem(`${siteName ? siteName : 'hp'}-auth`);
};

export const registerUser =
  (phoneToken, password, userAttrs, siteName, isLandBased) =>
  async (dispatch, getState) => {
    dispatch(authStart());
    try {
      const { user, userTokens } = await createUser(
        phoneToken,
        password,
        userAttrs,
        siteName
      );
      const unitToken = getState().unit.token;
      const usersUnitToken = await assignUnitToUser(
        userTokens.access.token,
        unitToken,
        isLandBased
      );
      dispatch(setUnitToken({ token: usersUnitToken }));
      dispatch(
        authSuccess({
          token: userTokens,
          user,
        })
      );
      setLocalUserParams(userTokens, siteName);
      localStorage.removeItem(siteName ? siteName : 'hp-token');
    } catch (error) {
      dispatch(authFail({ error: handleError(error) }));
    }
  };

export const updateUser =
  (userAttrs, siteName) => async (dispatch, getState) => {
    dispatch(authStart());
    try {
      const tokens = await getValidUserTokens(getState().auth.token, siteName);
      const user = await updateUserData(tokens.access.token, userAttrs);
      dispatch(updateUserSuccess({ user }));
    } catch (error) {
      dispatch(authFail({ error: handleError(error) }));
    }
  };

export const selectUser = (state) => state.auth.user;
export const selectLocalError = (state) => state.auth.localError;
export const selectTokens = (state) => {
  return state.auth.token;
};

export default authSlice.reducer;
