import React, { useContext } from 'react';
import { GamesList, ProductPage } from '@homeplay/components';
import classes from './VisoGames.module.scss';
import { SiteContext, selectVisoGames } from '@homeplay/app';
import visoGamesLogo from '../../../../shared/assets/images/viso-games-logo.png';
import { useSelector } from 'react-redux';

const VisoGames = () => {
  const { isDesktop } = useContext(SiteContext);
  const visoGames = useSelector(selectVisoGames);

  const getGameLink = (loadParams) => {
    return `https://${process.env.REACT_APP_ENV === 'prod' ? '' : 'vx-'}${loadParams.slug.replaceAll('_', '-')}.${process.env.REACT_APP_VISOTRONIX_BASE_URL}?token=${loadParams.player_token}&device=${isDesktop ? 'desktop' : 'mobile'}&mode=home_play&exit=${window.location.origin}/exit`;
  };

  return (
    <ProductPage getGameLink={getGameLink} customClass={classes.VisoGames}>
      <div className="container">
        <img
          className={classes.Logo}
          src={visoGamesLogo}
          alt="VisoGames"
          width="228"
          height="100"
        />
        {visoGames && <GamesList games={visoGames} />}
        <p className={classes.Info}>
          We are working hard to bring your favourite Viso Games here.
        </p>
        <p className={classes.Info + ' ' + classes.Light}>
          Watch this space as more games are coming soon!
        </p>
      </div>
    </ProductPage>
  );
};

export default VisoGames;
