import React, {useEffect, useRef, useState} from 'react';
import Input from "./index";
import classes from "./Input.module.scss";

const AmountInput = ({id, onChange, value, focus, error, allowDecimal = false}) => {
    const amountInput = useRef();
    const [caret, setCaret] = useState(null);

    useEffect(() => {
        if (focus && amountInput.current) {
            amountInput.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (caret !== null) {
            setCaretPosition(caret);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleChange = (changedValue) => {
        let normalizedValue = changedValue.replaceAll(' ', '').replaceAll('.00', '');
        if (allowDecimal) {
            normalizedValue = formatDecimalAmount(normalizedValue);
        } else {
            normalizedValue = normalizedValue.replaceAll('.', '');
        }
        if (normalizedValue.length < 10) {
            let newValue = formatBalance(normalizedValue);
            let caretPosition = getCaretPosition();
            if (caretPosition || caretPosition === 0) {
                if ((newValue.length - value.length) === 2) {
                    setCaret(caretPosition + 1);
                } else if ((value.length - newValue.length) === -2) {
                    setCaret(caretPosition -1);
                } else {
                    setCaret(caretPosition);
                }
            }
            onChange(newValue);
        }
    };

    const formatDecimalAmount = (value) => {
        const pointIndex = value.search(/\..*$/);
        if (pointIndex > 0) {
            let floatPart = "";
            const floatPartArray = value.slice(pointIndex + 1).match(/^\d{1,2}/);
            if (floatPartArray) {
                floatPart = floatPartArray[0];
            }
            return value.slice(0, pointIndex + 1) + floatPart;
        }
        return value;
    };

    const getCaretPosition = () => {
        if (amountInput.current && (amountInput.current.selectionStart || amountInput.current.selectionStart === '0')) {
            return amountInput.current.selectionStart;
        }
        return 0;
    };

    const setCaretPosition = (position) => {
        if (amountInput.current && amountInput.current.setSelectionRange) {
            amountInput.current.focus();
            amountInput.current.setSelectionRange(position, position);
        }
    };

    const formatBalance = (credits) => {
        return credits.replaceAll(',', '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <div className={classes.AmountInput}>
            <span className="color-primary">R</span>
            <Input
                refLink={amountInput}
                type="text"
                id={id}
                placeholder="Enter amount to withdraw"
                onChange={handleChange}
                value={value}
                pattern='^(\d+(,|\.|\d| )*){0,10}$'
                error={!!error}
                inputMode={allowDecimal ? 'decimal' : 'numeric'}
                errorMessage={error && error !== '-' ? error : null}
            />
        </div>
    );
};

export default React.memo(AmountInput);
