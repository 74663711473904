import React, {useEffect, useRef, useState} from 'react';
import Input from "./index";
import {getValidPhoneNumber, setMaskForPhone} from "@homeplay/utils";

const PhoneInput = ({id, onChange, value, focus, autocomplete = 'off'}) => {
    const phoneInput = useRef();
    const [caret, setCaret] = useState(null);
    const [prevValue, setPrevValue] = useState('');

    useEffect(() => {
        if (focus && phoneInput.current) {
            phoneInput.current.focus();
        }
        onChange(setMaskForPhone(value));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (caret !== null && prevValue !== '') {
            let newCaretPosition = caret;
            if (newCaretPosition < 0) {
                newCaretPosition *= -1;
            }
            if (value[newCaretPosition - 1] === ' ') {
                if (caret < 0) {
                    newCaretPosition--;
                } else {
                    newCaretPosition++;
                }
            }
            setCaretPosition(newCaretPosition);
        }
        setPrevValue(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleChange = (changedValue, event) => {
        if (event && event.nativeEvent && event.nativeEvent.inputType && event.nativeEvent.inputType === "insertFromPaste") {
            onChange(setMaskForPhone(changedValue));
            return;
        }

        let newValue = changedValue;
        let caretPosition = getCaretPosition();
        if (caretPosition || caretPosition === 0) {
            if (changedValue.length < value.length) {
                if (value[caretPosition] === ' ') {
                    newValue = newValue.slice(0, caretPosition - 1) + newValue.slice(caretPosition);
                    caretPosition--;
                }
                caretPosition *= -1;
            }
            setCaret(caretPosition);
        }
        onChange(setMaskForPhone(newValue));
    };

    const getCaretPosition = () => {
        if (phoneInput.current && (phoneInput.current.selectionStart || phoneInput.current.selectionStart === '0')) {
            return phoneInput.current.selectionStart;
        }
        return 0;
    };

    const setCaretPosition = (position) => {
        if (phoneInput.current && phoneInput.current.setSelectionRange) {
            phoneInput.current.focus();
            phoneInput.current.setSelectionRange(position, position);
        }
    };

    return (
        <Input
            refLink={phoneInput}
            type="tel"
            id={id}
            placeholder="Enter Your Phone Number"
            onChange={handleChange}
            value={value}
            pattern='^\+?[ 0-9]{0,15}$'
            error={!getValidPhoneNumber(value)}
            inputMode='tel'
            autocomplete={autocomplete}
        />
    );
};

export default React.memo(PhoneInput);
