import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Layout } from '@homeplay/components';
import {
  Auth,
  CashIn,
  CashOut,
  Club888,
  Fafi,
  GreenDream,
  InfoPage,
  PlaynGo,
  PPLiveCasino,
  PTClub,
  VisoGames,
  NetEnt,
  ResetPassword,
  RLN,
  OnlyPlay,
  OLN,
  Statement,
  BonusGames,
  EditProfile,
  Aviator,
} from '@homeplay/containers';
import {
  SiteContextProvider,
  UnitSocketProvider,
  autoLogin,
  selectHHConfigs,
  selectProducts,
  selectUser,
} from '@homeplay/app';
import MainPage from './containers/MainPage';
import { useDispatch, useSelector } from 'react-redux';
import { PRODUCTS } from '@homeplay/api';

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const hhConfigs = useSelector(selectHHConfigs);
  const user = useSelector(selectUser);

  let isTouchUnit = false;
  const query = new URLSearchParams(location.search);
  for (let param of query.entries()) {
    if (param[0] === 'touchscreen') {
      isTouchUnit = true;
    }
  }
  if (isTouchUnit) {
    sessionStorage.setItem('hp-touchscreen', '1');
  } else if (sessionStorage.getItem('hp-touchscreen')) {
    isTouchUnit = true;
  }

  useEffect(() => {
    dispatch(autoLogin(null, isTouchUnit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let enabledProducts = {
    'only-play': true,
    'pp-live-casino': true,
    rln: true,
    fafi: true,
    oln: true,
  };
  let enabledHH = true;
  if (products) {
    enabledHH = !!hhConfigs;
    enabledProducts = {};
    products.forEach((product) => {
      enabledProducts[product.id] = true;
    });
  }

  return (
    <SiteContextProvider siteName={null} isTouchUnit={isTouchUnit}>
      <UnitSocketProvider siteName={null} isTouchUnit={isTouchUnit}>
        <Layout>
          <Routes>
            <Route path={'/'} element={<MainPage />} />
            <Route path={`${PRODUCTS.aviator.id}/*`} element={<Aviator />} />
            <Route path={`${PRODUCTS.ptc.id}/*`} element={<PTClub />} />
            <Route path={`${PRODUCTS.club888.id}/*`} element={<Club888 />} />
            <Route path={`${PRODUCTS.playnGo.id}/*`} element={<PlaynGo />} />
            <Route
              path={`${PRODUCTS.greenDream.id}/*`}
              element={<GreenDream />}
            />
            <Route
              path={`${PRODUCTS.visoGames.id}/*`}
              element={<VisoGames />}
            />
            <Route path={`${PRODUCTS.netEnt.id}/*`} element={<NetEnt />} />
            {enabledProducts[PRODUCTS.fafi.id] && (
              <Route path={`${PRODUCTS.fafi.id}/*`} element={<Fafi />} />
            )}
            {enabledProducts[PRODUCTS.onlyPlay.id] && (
              <Route
                path={`${PRODUCTS.onlyPlay.id}/*`}
                element={<OnlyPlay />}
              />
            )}
            {enabledProducts[PRODUCTS.ppLiveCasino.id] && (
              <Route
                path={`${PRODUCTS.ppLiveCasino.id}/*`}
                element={<PPLiveCasino />}
              />
            )}
            {enabledProducts[PRODUCTS.rln.id] && (
              <Route path={`${PRODUCTS.rln.id}/*`} element={<RLN />} />
            )}
            {enabledProducts[PRODUCTS.oln.id] && (
              <Route path={`${PRODUCTS.oln.id}/*`} element={<OLN />} />
            )}
            <Route path={'auth'} element={<Auth />} />
            <Route path={'reset-password'} element={<ResetPassword />} />
            <Route path={'statement'} element={<Statement />} />
            <Route path={'cashin/*'} element={<CashIn />} />
            <Route path={'cashout/*'} element={<CashOut />} />
            {enabledHH && <Route path={'happy-hour'} element={<InfoPage />} />}
            {enabledHH && (
              <Route path={'bonus-games'} element={<BonusGames />} />
            )}
            {!!user && <Route path={'statement'} element={<Statement />} />}
            {!!user && (
              <Route path={'edit-profile'} element={<EditProfile />} />
            )}
            <Route path="*" element={<Navigate to=".." replace />} />
          </Routes>
        </Layout>
      </UnitSocketProvider>
    </SiteContextProvider>
  );
}

export default App;
