import React, { useContext, useEffect, useRef, useState } from 'react';
import { selectUser, SiteContext, updateUser } from '@homeplay/app';
import { Page } from '@homeplay/components';
import Button from '@homeplay/components/src/UI/Button';
import classes from '../UserAccount.module.scss';
import Input from '@homeplay/components/src/UI/Input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const EditProfile = () => {
  const { siteName, isTouchUnit, screenKeyboard } = useContext(SiteContext);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const focusInput = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (focusInput.current) {
      focusInput.current.focus();
    }
  }, []);

  const isInputsValid = () => {
    return firstName.length > 1 && lastName.length > 1;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      updateUser(
        { firstName: firstName.trim(), lastName: lastName.trim() },
        siteName
      )
    );
    navigate(`/${siteName}`);
  };

  return (
    <Page
      headerContent={'Edit Profile'}
      customClass={
        classes.Auth +
        (isTouchUnit && !!screenKeyboard ? ' screen-keyboard' : '')
      }
    >
      <div className="container">
        <div>
          <form name="form" onSubmit={handleSubmit}>
            <label className="label" htmlFor="firstName">
              Your First Name
            </label>
            <Input
              refLink={focusInput}
              type="text"
              id="firstName"
              placeholder="Enter Your First Name"
              onChange={setFirstName}
              value={firstName}
              pattern="^[a-zA-Z\d\-\s]+$"
              error={!firstName.length}
              autocomplete="given-name"
            />

            <label className="label" htmlFor="lastName">
              Your Last Name
            </label>
            <Input
              type="text"
              id="lastName"
              placeholder="Enter Your Last Name"
              onChange={setLastName}
              value={lastName}
              pattern="^[a-zA-Z\d\-\s]+$"
              error={!lastName.length}
              autocomplete="family-name"
            />

            <Button disabled={!isInputsValid()} customClass="d-block">
              Save
            </Button>
          </form>
        </div>
      </div>
    </Page>
  );
};

export default EditProfile;
