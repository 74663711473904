import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getValidPhoneNumber } from '@homeplay/utils';
import {
  SiteContext,
  authFail,
  authStart,
  disableAuthLoading,
  login,
  resetAuthLocalError,
  selectLocalError,
  selectUser,
} from '@homeplay/app';
import { Page } from '@homeplay/components';
import PhoneInput from '@homeplay/components/src/UI/Input/PhoneInput';
import Button from '@homeplay/components/src/UI/Button';
import Registration from './Registration';
import EnterPassword from './EnterPassword';
import ResetPassword from './ResetPassword';
import classes from '../UserAccount.module.scss';
import { handleError, startRegistration } from '@homeplay/api';

const Auth = () => {
  const { siteName, isTouchUnit, screenKeyboard } = useContext(SiteContext);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const passwordError = useSelector(selectLocalError);
  const focusInput = useRef();
  const [authStep, setAuthStep] = useState(1); // 1 - enter phone; 2 - login; 3 - registration; 4 - reset password
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (focusInput.current) {
      focusInput.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setStep = (step) => {
    if (passwordError) {
      dispatch(resetAuthLocalError());
    }
    setAuthStep(step);
  };

  const onSubmitPhone = async (event) => {
    event.preventDefault();
    dispatch(authStart());
    try {
      await startRegistration(phoneNumber, siteName);
      setStep(3);
      dispatch(disableAuthLoading());
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.code ===
          'users_ctx:users:validation:phone:not_unique'
      ) {
        setStep(2);
        dispatch(disableAuthLoading());
      } else {
        dispatch(authFail({ error: handleError(error) }));
      }
    }
  };

  if (user) {
    return <Navigate to={`/${siteName}`} replace />;
  }

  switch (authStep) {
    case 2:
      return (
        <EnterPassword
          title={'Enter Password'}
          phoneNumber={phoneNumber}
          label="Welcome"
          placeholder="Type Your Password"
          error={passwordError}
          onSubmit={(password) =>
            dispatch(login(phoneNumber, password, siteName, isTouchUnit))
          }
          onClickBack={() => setStep(0)}
          onClickResetPassword={() => setStep(4)}
          validation={false}
        />
      );

    case 3:
      return (
        <Registration
          phoneNumber={phoneNumber}
          onClickBack={() => setStep(0)}
        />
      );

    case 4:
      return (
        <ResetPassword
          phoneNumber={phoneNumber}
          onClickBack={() => setStep(1)}
        />
      );

    default:
      return (
        <Page
          headerContent="User Login / Registration"
          customClass={
            classes.Auth +
            (isTouchUnit && !!screenKeyboard ? ' screen-keyboard' : '')
          }
        >
          <div className="container">
            <form name="form" onSubmit={onSubmitPhone}>
              <label className="label" htmlFor="id">
                Your Phone
              </label>
              <PhoneInput
                id="id"
                onChange={setPhoneNumber}
                value={phoneNumber}
                focus={true}
                autocomplete="id"
              />
              <Button
                disabled={!getValidPhoneNumber(phoneNumber)}
                customClass="d-block"
              >
                Enter
              </Button>
            </form>
          </div>
        </Page>
      );
  }
};

export default Auth;
